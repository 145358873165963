<template>
    <div class="container mb-5 mt-4 mt-sm-5">
        <router-view />
    </div>
</template>

<script>
export default {
    
};
</script>